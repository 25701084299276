
  <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/3.4.1/css/bootstrap.min.css">



<br>
<section class="mb-5">

    <div class="row">
      <div class="col-md-6 mb-4 mb-md-0" style="border-style: groove;">

  
            <div class="col-12 mb-0">
              <lib-ngx-image-zoom
              [thumbImage]=myThumbnail
              [fullImage]=myFullresImage
              [magnification]="1.5"
              [enableScrollZoom]="true"
              [enableLens]="true"
              [lensWidth]="300"
          ></lib-ngx-image-zoom>
              <div style="    padding-left: 200px;">
                <h3>{{currentObject.title}}</h3>
              </div>
            </div>
      
      </div>
      
      <div class="col-md-6" style="
      background-color: aliceblue;">
  
        <h3>{{currentObject.title}}</h3>

        <p><span class="mr-1"><strong>Rs- {{currentObject.price}}</strong></span></p>
        <p><b>About Product -</b> </p>
        <p class="pt-1">{{currentObject.about}}.</p>

        
        <p><b> Description -</b></p>
        <p [innerHTML]="currentObject.description"></p>
        <p><b>Features -</b> </p>

        <p [innerHTML]="currentObject.features"></p>
        <hr>
        <div class="table-responsive mb-2">
         
        </div>
        <button type="button" class="btn btn-primary btn-md mr-1 mb-2" [routerLink]="['/addenquiry']"  title="Add Enquiry">Add Enquiry</button> | 
        <!-- <button type="button" class="btn btn-primary btn-md mr-1 mb-2" [routerLink]="['']" title="Back">Back To Product</button> -->
          <a href="#product" class="btn btn-danger" role="button">Back</a>
       
      
      </div>
    </div>




    <body>
      <div class="container" *ngIf='similar_offer !=""'>
        <div class="row">
          <div class="col-md-12">
            <h2>Similar <b>Products</b></h2>
            <div id="myCarousel" class="carousel slide" data-ride="carousel" data-interval="0">
            <!-- Carousel indicators -->
            <!-- <ol class="carousel-indicators">
              <li data-target="#myCarousel" data-slide-to="0" class="active"></li>
              <li data-target="#myCarousel" data-slide-to="1"></li>
              <li data-target="#myCarousel" data-slide-to="2"></li>
            </ol>    -->
            <!-- Wrapper for carousel items -->
            <div class="carousel-inner">
              <div class="carousel-item active">
                <div class="row">
                  <div class="col-sm-3" *ngFor="let item of similar_offer">
                    <div class="thumb-wrapper" >
                      <div class="img-box" stype="border-style: groove;">
                        <img src="{{item.product_image}}" class="img-fluid" alt="">
                      </div>
                      <div class="thumb-content">
                         <h4>{{item.title}}</h4>
                        <p class="item-price"><span> Rs {{item.price}}</span></p>
                        <div class="star-rating">
                          
                        </div>
                        <button class="btn btn-primary" type="Check Detail"  (click)='checkdetail("yes",item.product_id)'>Check Detail</button>
                      </div>						
                    </div>
                  </div>
                  
                 
                </div>
              </div>
              
            </div>
            <!-- Carousel controls -->
            <!-- <a class="carousel-control-prev" href="#myCarousel" data-slide="prev">
              <i class="fa fa-angle-left"></i>
            </a>
            <a class="carousel-control-next" href="#myCarousel" data-slide="next">
              <i class="fa fa-angle-right"></i>
            </a> -->
          </div>
          </div>
        </div>
      </div>
      </body>
  
</section>

 
  <!--Section: Block Content-->
  <script>
      $(document).ready(function () {
  // MDB Lightbox Init
  $(function () {
    $("#mdb-lightbox-ui").load("mdb-addons/mdb-lightbox-ui.html");
  });
});
  </script>