import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

   public baseUrl;
   public currentUser: Observable<any>; 


   constructor(private http: HttpClient) { 
       this.baseUrl = 'http://mayankbarcode.in/backend/webservices/offer'; 
  }

    httpOptions = {
      headers: new HttpHeaders({
      'version' : '1.0.0'
    })
  }

    getData(url : any) : Observable<any> {
      return this.http.get(this.baseUrl+url, this.httpOptions);
    }

    postData(url :any, user : any) : Observable<any> { 
      return this.http.post(this.baseUrl+url,user, this.httpOptions);
   }

 

}
