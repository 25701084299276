
    
<!--     <div class="container">

        
       <p> Enquiry From</p>
      <form action="/action_page.php">
        
        <input type="text" id="fname" name="username" placeholder="Your Username.." [(ngModel)]="userDetail.username" #username ="ngModel" required>
    
        <input type="text" id="lname" name="mobile" placeholder="Your Mobile No.." [(ngModel)]="userDetail.mobile" #mobile ="ngModel" required>

        <input type="text" id="city" name="city" placeholder="Your City.." [(ngModel)]="userDetail.city" #city ="ngModel" required>
    
        <textarea id="subject" name="message" placeholder="Write something.." style="height:150px" [(ngModel)]="userDetail.message" #message ="ngModel" required></textarea>
    
        <button type="button" class="btn btn-primary btn-md mr-1 mb-2"  (click)="sendResponce()">Submit</button>

        <button type="button" class="btn btn-primary btn-md mr-1 mb-2" title="Back To Product" (click)="back()">Back To Product</button>
      </form>
    </div> -->


    <div class="container-contact100">
    <div class="wrap-contact100">
      <form class="contact100-form validate-form">
        <span class="contact100-form-title">
<!--           Send Us A Message
 -->        </span>

        <label class="label-input100" for="first-name">Tell Us Your Username *</label>
        <div class="wrap-input100 validate-input" data-validate="Type Username">
          <input id="first-name" class="input100" type="text" name="first-name" [(ngModel)]="userDetail.username" #username ="ngModel" required placeholder="Your Username..">
          <span class="focus-input100"></span>
        </div>
      

        <label class="label-input100" for="phone">Enter phone number *</label>
        <div class="wrap-input100">
          <input id="phone" class="input100" type="number" name="phone" onKeyPress="if(this.value.length==12) return false;" [(ngModel)]="userDetail.mobile" #mobile ="ngModel" required placeholder="Eg. +91 800 000000">
          <span class="focus-input100"></span>
        </div>

           <label class="label-input100" for="phone">Enter Your City *</label>
        <div class="wrap-input100">
          <input id="city" class="input100" type="text" name="city" placeholder="Eg. Indore" [(ngModel)]="userDetail.city" #city ="ngModel" required>
          <span class="focus-input100"></span>
        </div>


        <label class="label-input100" for="message">Message *</label>
        <div class="wrap-input100 validate-input" data-validate = "Message is required">
          <textarea id="message" class="input100" name="message" [(ngModel)]="userDetail.message" #message ="ngModel" required placeholder="Write us a message"></textarea>
          <span class="focus-input100"></span>
        </div>

        <div class="container-contact100-form-btn">
          <button class="contact100-form-btn" (click)="sendResponce()" title="Save">
            Send Message
          </button>
          <span>  &nbsp;&nbsp;</span>
          <button class="contact100-form-btn" title="Back To Product" (click)="back()">Back To Product</button>
        </div>
      </form>

      <div class="contact100-more flex-col-c-m" style="background-image: url('../assets/img/bg-01.jpg');">
        <div class="flex-w size1 p-b-47">
          <div class="txt1 p-r-25">
            <span class="lnr lnr-map-marker"></span>
          </div>

          <div class="flex-col size2">
            <span class="txt1 p-b-20">
              Address
            </span>

            <span class="txt2">
             MSB-213, New Siyaganj Patthar Godam Road, Near ICICI Bank, Indore GPO,
             Indore-452003, Madhya Pradesh
             India
            </span>
          </div>
        </div>

        <div class="dis-flex size1 p-b-47">
          <div class="txt1 p-r-25">
            <span class="lnr lnr-phone-handset"></span>
          </div>

          <div class="flex-col size2">
            <span class="txt1 p-b-20">
              Lets Talk
            </span>

            <span class="txt3">
              +91 98260 14727
            </span>
          </div>
        </div>

        <div class="dis-flex size1 p-b-47">
          <div class="txt1 p-r-25">
            <span class="lnr lnr-envelope"></span>
          </div>

          <div class="flex-col size2">
            <span class="txt1 p-b-20">
              General Support
            </span>

            <span class="txt3">
              mayankenterprisesindore@gmail.com
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>

  