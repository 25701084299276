
  <div class="maindiv">
  </div>
    

<section id="what-we-do" class="what-we-do">
    <div class="container">

      <div class="section-title">
        <h2>Our Profile</h2>
        <p>  <b>Mayank Enterprises, Indore, India </b>
          We, Mayank Enterprises, are a distinguished name involved in trading a commendable gamut of Barcode Printers and Scanners. Under this range, we offer Barcode Printer, Barcode Label, Ribbon, TSC Printer, Citizen Printer, Zebra Printer, Honeywell Scanner and Symbol Scanner. Along with this, Mobile Computer, Barcode Scanner and many more. This offered range of is procured from the accredited manufacturers of the industry, who hold expertise over their respective domain. Furthermore, these products are in compliance with the define parameters of the industry, signifying their high and peerless standards. Furthermore, to meet divergent demands of the clients,we offer all our products in different specifications..</p>
      </div>

      <!-- <div class="row">
        <div class="col-lg-4 col-md-6 d-flex align-items-stretch">
          <div class="icon-box">
            <div class="icon"><i class="bx bxl-dribbble"></i></div>
            <h4><a href="">Nature of Business</a></h4>
            <p>Manufacturer</p>
          </div>
        </div>

        <div class="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-md-0">
          <div class="icon-box">
            <div class="icon"><i class="bx bx-file"></i></div>
            <h4><a href="">Total Number of Employees</a></h4>
            <p>Upto 10 People</p>
          </div>
        </div>

        <div class="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-lg-0">
          <div class="icon-box">
            <div class="icon"><i class="bx bx-tachometer"></i></div>
            <h4><a href="">Year of Establishment</a></h4>
            <p>2010</p>
          </div>
        </div>
        


      </div> -->

    </div>
  </section>
  <section id="about" class="about">
    <div class="container">

      <div class="row">
        <div class="col-lg-6">
          <img src="assets/img/about.jpg" class="img-fluid" alt="">
        </div>
        <div class="col-lg-6 pt-4 pt-lg-0">
          <h3>About Us</h3>
          <p>
            Established in the year 2010 we, “Mayank Enterprises”, are a prominent
            organization engaged in Manufacturing, Trading and Service providing and
            supplying a commendable gamut of Industrial Printers and Scanners.</p>
             
            <p>Under the proficient mentorship of, &#39;Mr. Kiran Pradhan&#39; (Proprietor), we have
            been able to attained distinguished position in this market.</p>
        </div>
      </div>

    </div>
  </section>


  <!-- ======= Portfolio Section ======= -->
  <section id="product" class="portfolio">
    <div class="container">

      <div class="section-title">
        <h2>Products</h2>
        <p>Our Products with Catagery</p>
      </div>

      <div class="row">
        <div class="col-lg-12">
          <ul id="portfolio-flters" class="filter-active">
            <!-- <li data-filter="*" class="filter-active" (click)="onClick('all')">All</li> -->
            <li *ngFor="let item of catagery | slice:0:8"  [ngClass]="{'filter-active': selectedItem == item}"  (click)="onClick(item)">
                <span>{{item.cat_name}}</span>
            </li>
          </ul>
        </div>
      </div>

    
 
   
      <div class="content-wrapper"> <!-- All good food and web content is wrapped in something! -->
        <div class="grid-container"> <!-- The grid... the net... The Matrix! -->
          <div *ngFor="let product of product_category" class="portfolio-wrap" >
            <img src="{{product.product_image}}" alt="Placeholder image" class="img-fluid bg-image hover-zoom" >
           <br>
           <br>
            <div class="portfolio-info" style="padding-left: 20%;">
              <h5><a href="portfolio-details.html"  title="See details" [routerLink]="['/productdetail', product.product_id]">{{product.title}}</a></h5>
            </div>
          </div>
          <div *ngIf ='nostatus == true' class="portfolio-wrap">
                <h5>No Record Found !!!</h5>
          </div>
        </div> <!-- / .grid-container -->
      </div> <!-- / .content-wrapper -->
    </div>
  </section>
  

   <section id="contact" class="contact section-bg">
      <div class="container">

        <div class="section-title">
          <h2>Contact</h2>
          <p>Mayank Enterprises</p>
        </div>

        <div class="row mt-5 justify-content-center">

          <div class="col-lg-10">

            <div class="info-wrap">
              <div class="row">
                <div class="col-lg-4 info">
                  <i class="bi bi-geo-alt"></i>
                  <h4>Location:</h4>
                  <p>MSB-213, New Siyaganj Patthar Godam Road, Near ICICI Bank, Indore GPO,
                    <br>Indore-452003, Madhya Pradesh, India</p>
                </div>

                <div class="col-lg-4 info mt-4 mt-lg-0">
                  <i class="bi bi-envelope"></i>
                  <h4>Email:</h4>
                  <p>mayankenterprisesindore@gmail.com</p>
                </div>

                <div class="col-lg-4 info mt-4 mt-lg-0">
                  <i class="bi bi-phone"></i>
                  <h4>Call:</h4>
                  <p> +91-98260 14727</p>
                </div>
              </div>
            </div>

          </div>

        </div>

        <div class="row mt-5 justify-content-center">
          <div class="col-lg-10">
            <form action="forms/contact.php" method="post" role="form" class="php-email-form">
              <div class="row">
                <div class="col-md-6 form-group">
                  <input type="text" name="name" class="form-control" id="name" placeholder="Your Name" [(ngModel)]="userDetail.name" #name ="ngModel" required>
                </div>
                <div class="col-md-6 form-group mt-3 mt-md-0">
                  <input type="email" class="form-control" name="email" id="email" placeholder="Your Email" [(ngModel)]="userDetail.email"  #email ="ngModel" required>
                </div>
              </div>
              <div class="form-group mt-3">
                <input type="text" class="form-control" name="subject" id="subject" placeholder="Subject" [(ngModel)]="userDetail.subject"  #subject ="ngModel" required>
              </div>
              <div class="form-group mt-3">
                <textarea class="form-control" name="message" rows="5" placeholder="Message" [(ngModel)]="userDetail.message"  #message ="ngModel" required></textarea>
              </div>
             
              <div class="text-center"><button type="submit" (click)="sendResponce()">Send Message</button></div>
            </form>
          </div>
        </div>

      </div>
  </section>


  <script src="https://code.jquery.com/jquery-3.6.0.js" ></script>
  <script>
    $(document).on('click','ul li',function(){
      alert('dddd')
      $(this).addClass('active').siblings().removeClass('active')
    })
  </script>