<app-header></app-header>
<ngx-loading-bar color="#3498db" height="4px"></ngx-loading-bar>
<ngx-spinner
  bdColor="rgba(51,51,51,0.8)"
  size="medium"
  color="#fff"
  type="ball-scale-multiple"
>
  <p style="font-size: 20px; color: white">Loading...</p>
</ngx-spinner>
<router-outlet></router-outlet>
<app-footer></app-footer>