import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ProductdetailComponent } from './productdetail/productdetail.component';
import { AddenquiryComponent } from './addenquiry/addenquiry.component';

const routes: Routes = [
  {
  path:'',component:DashboardComponent
  },
  { path: 'productdetail/:id',
  component: ProductdetailComponent
  },
  { path: 'addenquiry',
  component: AddenquiryComponent
  }];


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
