import { Component, OnInit } from '@angular/core';
import { ApiService } from '../api.service';
import { NgxSpinnerService } from "ngx-spinner";
import {ToastrService} from 'ngx-toastr';
import { Router } from '@angular/router';

@Component({
  selector: 'app-addenquiry',
  templateUrl: './addenquiry.component.html',
  styleUrls: ['./addenquiry.component.css']
})
export class AddenquiryComponent implements OnInit {
  public contactParam :any;
  public id :any;
  public userDetail = {
    'username'   : '',
    'mobile'     : '',
    'city'       : '',
    'message'    : ''
  }

  constructor(public BackendApiService : ApiService,
              private spinner: NgxSpinnerService,
              private toastrService: ToastrService,
              public Router : Router) { }

  ngOnInit(): void {
  window.scroll(0,0);
  }

   public numbersOnlyValidator(event: any) {
    const pattern = /^[0-9\-]*$/;
    if (!pattern.test(event.target.value)) {
      event.target.value = event.target.value.replace(/[^0-9\-]/g, "");
    }
  }

  sendResponce(){

    if (this.userDetail.username =='' && this.userDetail.mobile =='' && this.userDetail.city =='' && this.userDetail.message=='') {
      this.toastrService.error('Please Select All Fields Or Try Again !!!');
      return;
  }

    this.contactParam = {'username':this.userDetail.username,
                         'mobile':this.userDetail.mobile,
                         'city':this.userDetail.city,
                         'message':this.userDetail.message};


    this.BackendApiService.postData('/enquiryNow',this.contactParam).subscribe(data=> {
    this.spinner.hide();
      if(data.status){
          this.userDetail.username ='';
          this.userDetail.mobile='';
          this.userDetail.city='';
          this.userDetail.message='';
          this.spinner.hide();
          this.toastrService.success('Our Team contact you soon !!!');
      }else{
          this.spinner.hide();
          this.toastrService.error('something went wrong please try again');
      }
    })


  }


  back(){
    this.id = localStorage.getItem('id');
    this.Router.navigate(['/productdetail',this.id])
  }

}
