import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ActivatedRoute  } from '@angular/router';
import { ApiService } from '../api.service';
import { NgxSpinnerService } from "ngx-spinner";


@Component({
  selector: 'app-productdetail',
  templateUrl: './productdetail.component.html',
  styleUrls: ['./productdetail.component.css']
})
export class ProductdetailComponent implements OnInit {
  public id : string;
  public ids: string;
  public param:any;
  public currentObject : any ={};
  public similar_offer : any ={};
  closeResult = '';
  public myThumbnail: string;
  public myFullresImage: string;
  constructor( private router : Router,  private route : ActivatedRoute,public BackendApiService : ApiService,private spinner: NgxSpinnerService) {
  
   }
   

  ngOnInit(): void {
    this.checkdetail('No','')
    window.scroll(0,0);
  }
  

  checkdetail(param,ids){
  if(param =='yes'){
      this.id = ids
      localStorage.setItem('id', this.id);
  }else if( param == 'No'){
      this.id = this.route.snapshot.paramMap.get('id');
      localStorage.setItem('id', this.id);
  }

  this.spinner.show();
  this.param = { "product_id":this.id}

  this.BackendApiService.postData('/product_details',this.param).subscribe(data=> {
    if(data.status){
      this.spinner.hide();
      window.scroll(0,0);
       this.currentObject = data.data.product_details;
       this.similar_offer = data.data.similar_offer;
       this.myThumbnail    = this.currentObject.product_image.image;
       this.myFullresImage = this.currentObject.product_image.image;

    }else{
      this.spinner.hide();
      console.log('no record found !!!');
    }
  })
}

  
   
}
