import { Component, OnInit } from '@angular/core';
import { ApiService } from '../api.service';
import { NgxSpinnerService } from "ngx-spinner";
import {ToastrService} from 'ngx-toastr';


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  public catagery :any;
  public item:any;
  public product_category :any;
  public numbers :any;
  public param :'all';
  public norecord :'No Record Found !!!'
  public nostatus :boolean = false;
  public contactParam :any;
  selectedItem:any;
  public userDetail = {
    'email'    : '',
    'name'     : '',
    'subject'  : '',
    'message'  : ''
  }

  constructor(public BackendApiService : ApiService,private spinner: NgxSpinnerService,private toastrService: ToastrService) {}
  

  ngOnInit(): void {

    this.BackendApiService.getData('/list_category').subscribe(data=> {
      if(data.status){
         this.catagery = data.data;
      }else{
        console.log('no record found !!!');
      }
    })

    this.onClick('all')
  }

  onClick(param){
    this.selectedItem = param;  
      this.spinner.show();
      if(param == 'all'){
        param = {'category_id':'0'};
      }else{
        param = {'category_id':param.cat_id};
      }
      this.BackendApiService.postData('/product_by_category',param).subscribe(data=> {
        if(data.status){
          this.spinner.hide();
            if(typeof data.data.by_category !== 'undefined' && data.data.by_category.length > 0){
            this.product_category = data.data.by_category;
            this.nostatus = false;
          }else{
            this.product_category =[]
            this.nostatus = true;
            this.toastrService.error('NO Record Found For This catagery!!!');
          }
        }else{
            this.spinner.hide();
        }
      })
  }

  
  sendResponce(){

    
 if (this.userDetail.name =='' && this.userDetail.email =='' && this.userDetail.subject =='' && this.userDetail.message=='') {
      this.toastrService.error('Please Select All Fields Or Try Again !!!');
      return;
  }

    this.contactParam = {'username':this.userDetail.name,
                         'email':this.userDetail.email,
                         'subject':this.userDetail.subject,
                         'message':this.userDetail.message};


    this.BackendApiService.postData('/contactus',this.contactParam).subscribe(data=> {
      if(data.status){
          this.spinner.hide();
          this.toastrService.success('Our Team contact you soon !!!');
      }else{
          this.spinner.hide();
          this.toastrService.error('something went wrong please try again');
      }
    })

  }



}
